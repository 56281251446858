/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Integrations are hard. By definition, an integration connects two or more systems to enable data exchange. These systems are often using disparate databases, auth, and APIs. And it's common for these systems to be under development also, which means you are trying to hit a moving target."), "\n", React.createElement(_components.p, null, "That said, using well-defined requirements to build integrations can speed time to market for your product's integrations, reduce development, deployment, and support costs, and ensure that end users are pleased with the results."), "\n", React.createElement(_components.h2, {
    id: "what-are-integration-requirements",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-integration-requirements",
    "aria-label": "what are integration requirements permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are integration requirements?"), "\n", React.createElement(_components.p, null, "Integration requirements describe what an integration does and how it is expected to function. A collection of these is called a specification."), "\n", React.createElement(_components.p, null, "We sometimes talk about high-level versus detailed requirements. Here's an example of a high-level integration requirement: \"The integration syncs order data between the CRM and Accounting systems.\""), "\n", React.createElement(_components.p, null, "If we were to start developing an integration based on this requirement, we would end up in an unknown space very quickly. High-level requirements help us define the box, but we need detailed requirements to fill the box. When we refer to requirements for the balance of this post, we are talking about detailed requirements."), "\n", React.createElement(_components.h2, {
    id: "why-are-requirements-critical-for-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-are-requirements-critical-for-integrations",
    "aria-label": "why are requirements critical for integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why are requirements critical for integrations?"), "\n", React.createElement(_components.p, null, "Requirements for integrations are often considered unimportant compared to requirements for other software. This thinking about integrations stems from a simplistic understanding: \"It just copies data from one system to another. How hard can it be?\""), "\n", React.createElement(_components.p, null, "Integrations are much like icebergs. Most of the integration is not visible. So, if we only create requirements for what we can easily see, we'll build something ill-suited to the underlying business need."), "\n", React.createElement(_components.p, null, "Integration requirements are essential to establish an agreed-upon integration scope for all the stakeholders, from engineering to end-users. Yes, even if your devs take an agile approach to integration development, solid requirements make the difference between success and struggle."), "\n", React.createElement(_components.h2, {
    id: "common-questions-for-integration-requirements-gathering",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-questions-for-integration-requirements-gathering",
    "aria-label": "common questions for integration requirements gathering permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common questions for integration requirements gathering"), "\n", React.createElement(_components.p, null, "The nice thing about gathering requirements for integrations is that we know, at the highest level, what integrations do. This knowledge allows us to narrow the scope of requirements questions from the beginning."), "\n", React.createElement(_components.p, null, "Here are standard questions to solicit integration requirements:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "What will cause the integration to run, and how often will it run?"), "\n", React.createElement(_components.li, null, "Will this be a one-way or two-way integration (or something else)?"), "\n", React.createElement(_components.li, null, "What auth method will each system use?"), "\n", React.createElement(_components.li, null, "What API will each system use?"), "\n", React.createElement(_components.li, null, "What data is to be transferred?"), "\n", React.createElement(_components.li, null, "What transport languages will be used?"), "\n", React.createElement(_components.li, null, "What transfer protocols will be used?"), "\n", React.createElement(_components.li, null, "Will any data need to be modified within the integration?"), "\n"), "\n", React.createElement(_components.p, null, "Let's look at these questions in detail and see how they help us build a complete picture of integration requirements."), "\n", React.createElement(_components.h3, {
    id: "what-will-cause-the-integration-to-run-and-how-often-will-it-run",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-will-cause-the-integration-to-run-and-how-often-will-it-run",
    "aria-label": "what will cause the integration to run and how often will it run permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What will cause the integration to run, and how often will it run?"), "\n", React.createElement(_components.p, null, "Integrations generally have some type of trigger that causes them to run. These triggers may be ", React.createElement(_components.a, {
    href: "../optimizing-for-event-driven-integrations/"
  }, "event-based"), " (for example, a change in an order record in the CRM) or ", React.createElement(_components.a, {
    href: "../when-use-scheduled-integrations/"
  }, "scheduled"), " (for example, every Thursday at 7:15 AM local time). They may also have manual triggers (if something needs to be forced to run or if the need for data transfer can't be planned programmatically)."), "\n", React.createElement(_components.h3, {
    id: "will-this-be-a-one-way-or-two-way-integration-or-something-else",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#will-this-be-a-one-way-or-two-way-integration-or-something-else",
    "aria-label": "will this be a one way or two way integration or something else permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Will this be a one-way or two-way integration (or something else)?"), "\n", React.createElement(_components.p, null, "A one-way integration sends data from one system to another. A two-way integration sends and receives data. In addition, a single integration could involve three or more systems, each of which could send and/or receive data."), "\n", React.createElement(_components.p, null, "Since one-way integrations tend to be simpler than N-way integrations, knowing the direction of the integration is a significant factor in determining the overall integration effort."), "\n", React.createElement(_components.h3, {
    id: "what-auth-method-will-each-system-use",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-auth-method-will-each-system-use",
    "aria-label": "what auth method will each system use permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What auth method will each system use?"), "\n", React.createElement(_components.p, null, "Auth (or authentication) is needed to ensure integrations run securely. Knowing the ", React.createElement(_components.a, {
    href: "../making-best-auth-decisions-saas-integrations/"
  }, "auth method"), " for each system lets us determine a crucial part of what we'll need to configure for the integration. Systems generally use basic auth, API keys, or OAuth (though other auth types exist). Along with the type of auth, there is the question of whether auth is defined per user or system."), "\n", React.createElement(_components.h3, {
    id: "what-api-will-each-system-use",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-api-will-each-system-use",
    "aria-label": "what api will each system use permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What API will each system use?"), "\n", React.createElement(_components.p, null, "Not all integrations need APIs, but most of them will. Knowing the ", React.createElement(_components.a, {
    href: "../different-apis-and-how-they-work/"
  }, "types of APIs"), " being used gives developers a good idea of the rules and restrictions they'll be working with. REST APIs are used more often than any other type of API, but ", React.createElement(_components.a, {
    href: "../anatomy-webhook-http-request/"
  }, "webhooks"), " (sometimes called reverse APIs) are increasingly common. And some integrations may have an API on one end and a file import/export or something else on the other."), "\n", React.createElement(_components.h3, {
    id: "what-data-is-to-be-transferred",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-data-is-to-be-transferred",
    "aria-label": "what data is to be transferred permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What data is to be transferred?"), "\n", React.createElement(_components.p, null, "Data transfer is the core of an integration, so getting this right is critical. We often speak loosely about data, using terms like \"order data\" (going back to our high-level requirement example). Does it mean records from the order table in the sending or receiving system's database (or both)? Does the order data include data from any other tables? If there is conflicting data in the two systems, which one overrides the other?"), "\n", React.createElement(_components.p, null, "In many cases, end users look at data on a screen and don't realize that the screen is displaying data from half a dozen database tables. When you specify the data to be transferred, doing so field by field is essential. If \"order data\" is comprised of 35 fields, explicitly list all those fields."), "\n", React.createElement(_components.h3, {
    id: "what-transport-languages-will-be-used",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-transport-languages-will-be-used",
    "aria-label": "what transport languages will be used permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What transport languages will be used?"), "\n", React.createElement(_components.p, null, "People use human languages to communicate. Integrations use transport languages to encode the data which is sent between them. ", React.createElement(_components.a, {
    href: "../xml-vs-json/"
  }, "XML and JSON"), " are the most common transport languages (also called data exchange formats or data interchange formats), but there are others."), "\n", React.createElement(_components.p, null, "Sending and receiving systems may use different transport languages. In that case, transport language decoding and encoding must be part of the integration."), "\n", React.createElement(_components.h3, {
    id: "what-transfer-protocols-will-be-used",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-transfer-protocols-will-be-used",
    "aria-label": "what transfer protocols will be used permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What transfer protocols will be used?"), "\n", React.createElement(_components.p, null, "In addition to being encoded in specific languages, integrations also use ", React.createElement(_components.a, {
    href: "../transfer-protocols-transport-languages-saas-integrations/"
  }, "transfer protocols"), " (a network protocol that supports data transfer between systems). While the APIs often determine these protocols, some systems may provide a choice of protocols."), "\n", React.createElement(_components.p, null, "HTTP (and HTTPS) are the most used transfer protocols for integrations running over the web. Integrations that are transferring files might rely on FTP, FTPS, or SFTP."), "\n", React.createElement(_components.h3, {
    id: "will-any-data-need-to-be-modified-within-the-integration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#will-any-data-need-to-be-modified-within-the-integration",
    "aria-label": "will any data need to be modified within the integration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Will any data need to be modified within the integration?"), "\n", React.createElement(_components.p, null, "We touched on translating/mapping between transport languages above, but that's only the start. You may also need to manipulate text strings, perform math calculations, and map data elements from dissimilar schemas. While these are the ", React.createElement(_components.a, {
    href: "../b2b-saas-integrations-working-with-the-messy-middle/"
  }, "most common ways to modify data"), " within an integration, this is not an exhaustive list."), "\n", React.createElement(_components.p, null, "To determine needed modifications to integration data, you must compare input data requirements with output data requirements to see what actions the integration must take to transform the input data to match the output data."), "\n", React.createElement(_components.h2, {
    id: "tips-for-integration-requirements-gathering",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tips-for-integration-requirements-gathering",
    "aria-label": "tips for integration requirements gathering permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tips for integration requirements gathering"), "\n", React.createElement(_components.p, null, "If you've received detailed answers to all the questions in the preceding section, you may not have a finished integration requirements specification, but you should have a good draft."), "\n", React.createElement(_components.p, null, "In addition to those specific questions, here are some tips to inform your overall process and help you turn that draft into a solid specification:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Talk to end users"), "\n", React.createElement(_components.li, null, "Talk to more users"), "\n", React.createElement(_components.li, null, "Seek out corner cases"), "\n", React.createElement(_components.li, null, "Engage with your tech partner"), "\n"), "\n", React.createElement(_components.h3, {
    id: "talk-to-end-users",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#talk-to-end-users",
    "aria-label": "talk to end users permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Talk to end users"), "\n", React.createElement(_components.p, null, "Every organization handles requirements gathering differently and expects different roles to contribute. That's fine if you can ensure that you are soliciting requirements information from everyone. And by everyone, we mean everyone who has something to contribute."), "\n", React.createElement(_components.p, null, "To ensure you capture a sufficiently detailed list of requirements, we recommend talking with end users who work with any part of the systems or process with which the integration will interact. If there are users who copy data from System A and place it into System B (and the integration is supposed to replace that manual step), talk with them. Get them to show you precisely what they are doing today and share their expectations of how the integration will change their workflow."), "\n", React.createElement(_components.h3, {
    id: "talk-to-more-users",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#talk-to-more-users",
    "aria-label": "talk to more users permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Talk to more users"), "\n", React.createElement(_components.p, null, "Remember to gather requirements from multiple customers, ensuring you capture the differences between those customers. 95% of the functionality may be identical between customers. But the 5% that is different is critical. Maybe some customers need to include one or more JPG files in the data transfer, while others don't need JPG files at all. Perhaps some customers only need 20 of 25 available data elements, but other customers need all 25."), "\n", React.createElement(_components.p, null, "To productize an integration, you'll need to document the differences (the 5%), decide what is essential to all your customers, and then build a single integration. Inputs and other values that should differ per customer can be set as configuration options, allowing you to build once and deploy many times."), "\n", React.createElement(_components.h3, {
    id: "seek-out-corner-cases",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seek-out-corner-cases",
    "aria-label": "seek out corner cases permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seek out corner cases"), "\n", React.createElement(_components.p, null, "As part of fleshing out the high-level requirements to get the level of detail you need for the specification, you'll need to identify the corner cases – scenarios that may not be immediately obvious but can have an oversized impact on the integration."), "\n", React.createElement(_components.p, null, "Examples of corner cases may include things like:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Middle name is optional in our product, but required in the system we are sending data to. What are we planning to do for records with no middle name value?"), "\n", React.createElement(_components.li, null, "The other system includes voided transactions. Can we process those into our product?"), "\n", React.createElement(_components.li, null, "Are there other record types that don't fit the standard pattern?"), "\n"), "\n", React.createElement(_components.p, null, "All of these questions come down to not making assumptions, but figuring out what the possible scenarios can be and how you will ensure that your integration can handle them. Sorting these out in the requirements phase will save your team a lot of time and rework during development."), "\n", React.createElement(_components.h3, {
    id: "engage-with-your-tech-partner",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#engage-with-your-tech-partner",
    "aria-label": "engage with your tech partner permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Engage with your tech partner"), "\n", React.createElement(_components.p, null, "To integrate your SaaS product with a tech partner's app, confirm that you have access to the corresponding API and app (and all available documentation). If you need an NDA with that partner before you and your team can dig into the details, get that done as quickly as possible."), "\n", React.createElement(_components.p, null, "When working with a tech partner, it is common to find that the integration is not as important to them as it is to you. As a result, they lack a sense of urgency and don't see answering your questions or meeting with your team as priorities. You must determine how to make gathering requirements a priority for them or at least come to a shared understanding concerning how quickly and thoroughly they will answer questions so you can plan accordingly."), "\n", React.createElement(_components.h2, {
    id: "requirements-are-foundational",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#requirements-are-foundational",
    "aria-label": "requirements are foundational permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Requirements are foundational"), "\n", React.createElement(_components.p, null, "There is more to building good integrations than gathering requirements. But you need solid requirements to build first-class integrations."), "\n", React.createElement(_components.p, null, "Asking questions to gather integration requirements can fill that box we mentioned earlier. But what do you do at that point? Do you hand the box over to your development team? Or do you implement an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " to supercharge your integration development, deployment, and support efforts?"), "\n", React.createElement(_components.p, null, "An embedded iPaaS provides:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A means to react quickly to new integration requests from prospects and customers"), "\n", React.createElement(_components.li, null, "A low-code designer with pre-built components"), "\n", React.createElement(_components.li, null, "The ability to write code when needed"), "\n", React.createElement(_components.li, null, "Collaboration with your current dev process and tools"), "\n", React.createElement(_components.li, null, "Infrastructure purpose-built to address security, availability, and reliability"), "\n", React.createElement(_components.li, null, "For technical non-devs to build, demo, test, deploy, and manage large numbers of integrations"), "\n"), "\n", React.createElement(_components.p, null, "If an embedded iPaaS is something you should consider for your SaaS integrations, ", React.createElement(_components.a, {
    href: "/contact/"
  }, "contact us"), " or ", React.createElement(_components.a, {
    href: "/request-a-demo/"
  }, "request a demo"), ", and we'll be glad to show you how Prismatic can power your integration strategy."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
